import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * `TileContent` is a wrapper component for `TileMedia` and `TileText`.
 * It provides orientation and alignment capabilities.
 *
 * Related components: [Tile](#tile), [TileText](#tiletext), [TileMedia](#tilemedia).
 *
 * Usage:
 *
 * ```jsx
 * import { TileContent } from '@one-thd/sui-atomic-components';
 * ```
 */
const TileContent = React.forwardRef((props, ref) => {

  const {
    alignItems = 'center',
    component: TileContentRoot = 'div',
    disableGutters = false,
    disableShrink = false,
    grow = false,
    orientation = 'horizontal',
    ...other
  } = props;

  const classes = classNames('sui-flex', {
    'sui-items-start': alignItems === 'start',
    'sui-items-center': alignItems === 'center',
    'sui-items-end': alignItems === 'end',
    'sui-flex-col': orientation === 'vertical',
    'sui-gap-2': !disableGutters,
    'sui-shrink-0': disableShrink,
    'sui-grow': grow,
  });

  return (
    <TileContentRoot
      className={classes}
      ref={ref}
      {...other}
    />
  );
});

TileContent.displayName = 'TileContent';

TileContent.propTypes = {
  /**
   * The alignment of the content
   * @default 'center'
   */
  alignItems: PropTypes.oneOf(['start', 'center', 'end']),
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: PropTypes.elementType,
  /**
   * If `true`, the gap is removed.
   * @default false
   */
  disableGutters: PropTypes.bool,
  /**
   * If `true`, prevents a flex item from shrinking.
   * @default false
   */
  disableShrink: PropTypes.bool,
  /**
   * If true, will set flex items to grow to fill available space.
   * @default false
   */
  grow: PropTypes.bool,
  /**
   * The component orientation. Sets the direction of flex items.
   * @default 'horizontal'
   */
  orientation: PropTypes.oneOf(['horizontal', 'vertical'])
};

export { TileContent };
